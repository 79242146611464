<template>
  <ScaleScreen :width="1920" :height="1080" class="scale-wrap">
    <div class="bgc">
      <div class="top_title">
        闽侯县可视化招商管理平台
        <dv-decoration-6
          style="
            width: 350px;
            height: 20px;
            position: absolute;
            z-index: 20;
            left: 762px;
            top: 50px;
          "
        />

        <dv-decoration-3
          style="
            width: 250px;
            height: 30px;
            position: absolute;
            z-index: 20;
            left: 260px;
            top: 25px;
          "
        />
        <dv-decoration-3
          style="
            width: 250px;
            height: 30px;
            position: absolute;
            z-index: 20;
            right: 260px;
            top: 25px;
          "
        />
        <div class="leftTime">{{ date }}</div>
        <div class="goBackstage" @click="goBackstage">进入管理后台</div>
      </div>
      <div class="top">
        <div
          class="title"
          style="padding-left: 40px; padding-top: 5px; font-size: 24px"
        >
          招商资源
        </div>
        <div class="content">
          <div class="contentItem" v-if="topList.length != 0">
            <dv-border-box-1 style="margin-top: 0px">
              <div class="contentItemTitle">整体资源</div>
              <div class="contentText">{{ total }}个</div>
            </dv-border-box-1>
          </div>
          <div class="contentItem" v-for="(v, i) in topList" :key="i">
            <dv-border-box-1 style="margin-top: 0px">
              <div class="contentItemTitle">{{ v.name }}</div>
              <div class="contentItemBody">
                <div class="total">
                  <p>总数</p>
                  <p style="margin-top: 5px">
                    <span
                      style="font-weight: bold; font-size: 16px; color: #fdfd97"
                      :class="String(v.area).length > 6 ? 'max' : ''"
                      >{{ v.num }}</span
                    >个
                  </p>
                </div>
                <div class="theMeasureOfArea">
                  <p>总面积</p>
                  <p style="margin-top: 5px">
                    <span
                      style="font-weight: bold; font-size: 14px; color: #fdfd97"
                      :class="String(v.area).length > 6 ? 'max' : ''"
                      >{{ v.area }}</span
                    >
                    ㎡
                  </p>
                </div>
              </div>
            </dv-border-box-1>
          </div>
        </div>
      </div>
      <div class="left">
        <dv-border-box-8>
          <div class="msg">
            <div class="msgLeft">
              <div
                class="msgItem"
                style="display: flex; margin-top: 20px"
                v-for="(v, i) in topList"
                :key="i"
              >
                <p>{{ v.name }}</p>
                <div style="margin-top: -5px; margin-left: 10px">
                  <p style="font-style: italic">
                    <span
                      style="font-size: 26px; color: #6ccbfa; font-weight: bold"
                      >{{ v.area }}</span
                    >
                    ㎡
                  </p>
                  <!-- <p
                    style="
                      font-style: italic;
                      color: #6ccbfa;
                      font-weight: bold;
                    "
                  >
                    57%
                  </p> -->
                </div>
              </div>

              <!-- <div style="display: flex; margin-top: 20px">
                <p>楼宇用地</p>
                <div style="margin-top: -5px; margin-left: 10px">
                  <p>
                    <span
                      style="
                        font-style: italic;
                        font-size: 26px;
                        color: #b8fdf0;
                        font-weight: bold;
                      "
                      >674</span
                    >
                    亩
                  </p>
                  <p
                    style="
                      font-style: italic;
                      color: #b8fdf0;
                      font-weight: bold;
                    "
                  >
                    18%
                  </p>
                </div>
              </div> -->
            </div>
            <!-- <div class="msgRight">
              <div style="display: flex; margin-top: 40px">
                <p>低效用地</p>
                <div style="margin-top: -5px; margin-left: 10px">
                  <p>
                    <span
                      style="
                        font-style: italic;
                        font-size: 26px;
                        color: #6ce5fb;
                        font-weight: bold;
                      "
                    >
                      972</span
                    >
                    亩
                  </p>
                  <p
                    style="
                      font-style: italic;
                      color: #6ce5fb;
                      font-weight: bold;
                    "
                  >
                    24%
                  </p>
                </div>
              </div>
              <div style="display: flex; margin-top: 20px">
                <p>厂房用地</p>
                <div style="margin-top: -5px; margin-left: 10px">
                  <p>
                    <span
                      style="
                        font-style: italic;
                        font-size: 26px;
                        color: #f7de7d;
                        font-weight: bold;
                      "
                      >366</span
                    >
                    亩
                  </p>
                  <p
                    style="
                      font-style: italic;
                      color: #f7de7d;
                      font-weight: bold;
                    "
                  >
                    24%
                  </p>
                </div>
              </div>
            </div> -->
          </div>
          <div
            style="
              width: 600px;
              height: 400px;
              margin-top: -70px;
              margin-left: 0px;
            "
          >
            <dv-active-ring-chart
              v-if="config.data.length != 0"
              :config="config"
              style="
                width: 600px;
                height: 400px;
                margin-top: -70px;
                margin-left: -50px;
              "
            />
          </div>

          <div class="leftBottom">
            <div class="leftBottomItem">
              <div class="img"></div>
              <div class="message">
                <div class="messageTitle">资源数量</div>
                <div class="messageNumber">
                  <div class="total">
                    <p>累计总数</p>
                    <p
                      style="
                        font-style: italic;
                        color: #f7de7d;
                        font-weight: bold;
                      "
                    >
                      {{ configList.resource_quantity }}个
                    </p>
                  </div>
                  <div class="month">
                    <p>当月</p>
                    <p
                      style="
                        font-style: italic;
                        color: #abf9fe;
                        font-weight: bold;
                      "
                    >
                      {{ configList.resource_quantity_lastmonth }}个
                    </p>
                  </div>
                  <div class="lastMonth">
                    <p>较上月</p>
                    <p
                      style="
                        font-style: italic;
                        color: #abf9fe;
                        font-weight: bold;
                      "
                      v-if="configList.resource_quantity_add >= 0"
                    >
                      {{ configList.resource_quantity_add }}个
                    </p>
                    <p
                      style="
                        font-style: italic;
                        color: #ec5e59;
                        font-weight: bold;
                      "
                      v-if="configList.resource_quantity_add < 0"
                    >
                      {{ configList.resource_quantity_add }}个
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftBottomItem">
              <div class="img"></div>
              <div class="message">
                <div class="messageTitle" style="margin-top: 8px">资源匹配</div>
                <div class="messageNumber">
                  <div class="total">
                    <p>累计总数</p>
                    <p
                      style="
                        font-style: italic;
                        color: #f7de7d;
                        font-weight: bold;
                      "
                    >
                      {{ configList.resource_matching_num }}个
                    </p>
                  </div>
                  <div class="month">
                    <p>当月</p>
                    <p
                      style="
                        font-style: italic;
                        color: #abf9fe;
                        font-weight: bold;
                      "
                    >
                      {{ configList.resource_matching_month }}个
                    </p>
                  </div>
                  <div class="lastMonth">
                    <p>较上月</p>
                    <p
                      style="
                        font-style: italic;
                        color: #abf9fe;
                        font-weight: bold;
                      "
                      v-if="configList.resource_matching_lastnum >= 0"
                    >
                      {{ configList.resource_matching_lastnum }}个
                    </p>
                    <p
                      style="
                        font-style: italic;
                        color: #ec5e59;
                        font-weight: bold;
                      "
                      v-if="configList.resource_matching_lastnum < 0"
                    >
                      {{ configList.resource_matching_lastnum }}个
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftBottomItem">
              <div class="img"></div>
              <div class="message">
                <div class="messageTitle" style="margin-top: 10px">
                  落地资源
                </div>
                <div class="messageNumber">
                  <div class="total">
                    <p>累计总数</p>
                    <p
                      style="
                        font-style: italic;
                        color: #f7de7d;
                        font-weight: bold;
                      "
                    >
                      {{ configList.landing_resources_num }}个
                    </p>
                  </div>
                  <div class="month">
                    <p>当月</p>
                    <p
                      style="
                        font-style: italic;
                        color: #abf9fe;
                        font-weight: bold;
                      "
                    >
                      {{ configList.landing_resources_month }}个
                    </p>
                  </div>
                  <div class="lastMonth">
                    <p>较上月</p>
                    <p
                      style="
                        font-style: italic;
                        color: #abf9fe;
                        font-weight: bold;
                      "
                      v-if="configList.landing_resources_last >= 0"
                    >
                      {{ configList.landing_resources_last }}个
                    </p>
                    <p
                      style="
                        font-style: italic;
                        color: #ec5e59;
                        font-weight: bold;
                      "
                      v-if="configList.landing_resources_last < 0"
                    >
                      {{ configList.landing_resources_last }}个
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dv-border-box-8>
      </div>
      <div class="right">
        <div class="title" style="padding-left: 30px; font-size: 24px">
          招商项目
        </div>
        <div class="projectTotal">
          项目总数：
          <span style="color: #6ccbfa; font-weight: bold">{{
            projectTotal
          }}</span>
        </div>
        <div class="projectBox">
          <div
            class="projectItem"
            v-for="(v, i) in projectData"
            :key="i"
            :style="{ backgroundImage: 'url(' + loginImage[i] + ')' }"
          >
            <dv-border-box-8
              :dur="5"
              style="
                width: 235px;
                margin-left: -3px;
                height: 95px;
                margin-top: -3px;
              "
            >
              <div class="projectTitle">{{ v.name }}</div>
              <div class="projectNumber">
                <div class="projectNumberLeft">
                  {{ v.total }}<span style="font-size: 16px">个</span>
                </div>
                <div class="projectNumberRight">
                  <p>
                    当月
                    <span style="color: #65d07f" v-if="v.month >= 0"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ v.month }}</span
                    >
                    <span style="color: #c34c4f" v-if="v.month < 0"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ v.month }}</span
                    >
                  </p>
                  <p style="margin-top: 5px" v-if="v.last_month >= 0">
                    较上月<span style="color: #65d07f"
                      >&nbsp;&nbsp;&nbsp;{{ v.last_month }}</span
                    >
                  </p>
                  <p style="margin-top: 5px" v-if="v.last_month < 0">
                    较上月<span style="color: #c34c4f"
                      >&nbsp;&nbsp;&nbsp;{{ v.last_month }}</span
                    >
                  </p>
                </div>
              </div>
            </dv-border-box-8>
          </div>
        </div>
        <div style="width: 100%; height: 20px"></div>
        <vue-seamless-scroll
          :data="projectList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <div class="pendingProcessing">
            <div class="pendingProcessingContainer">
              <div
                class="pendingProcessingItem"
                v-for="(v, i) in projectList"
                :key="i"
              >
                <div class="pendingProcessingText">{{ v.title }}</div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="center">
        <div class="img">
          <img src="../assets/adress.jpg" alt="" />
        </div>
        <div class="text">闽侯县</div>
        <fuzhouMap @mapId="handleMapClick" @detailId="detailClick"></fuzhouMap>
      </div>
      <!-- <el-dialog
        :title="towndetail.title"
        :visible.sync="show"
        :append-to-body="false"
        :modal-append-to-body="false"
        @close="closeDialog"
      >
        <div class="introduce" v-html="towndetail.content"></div>
      </el-dialog> -->
      <div id="container" v-if="show"></div>
    </div>
  </ScaleScreen>
</template>


<script>

import ScaleScreen from '../components/scale-screen/scale-screen.vue'
// import pieChart from './component/pieChart.vue'
import fuzhouMap from './component/map.vue'
import { getProjectList, getCarrierList, getToConfigure, getProjectData, TownDetail } from '@/api/index'
import { TMap } from '../TMap'

export default {
  name: 'App',
  components: {
    ScaleScreen,
    fuzhouMap
  },
  data () {
    return {
      config: {
        radius: '40%',
        activeRadius: '45%',
        data: [],
        digitalFlopStyle: {
          fontSize: 20
        },
        showOriginValue: true
      },
      topList: [],
      configList: [],
      projectList: [],
      total: '',
      projectData: [],
      mapId: '',
      projectTotal: '',
      show: false,
      date: '',
      title: '',
      towndetail: {},
      content: '<p>南屿镇(Nanyu Tow),福建省福州市间侯县（福州高新技术产业开发区托管)下辖镇，地处间候县南部，旗山东麓，大樟溪西畔，东接南通镇，西南与永泰县塘前乡接壤，西临永泰县葛怜镇，北邻上街镇，行政区域面积164.34平方千米，就至2019年末，南屿镇户籍人口71384人。</p> <img src="https://zhihuizhaoshang.wisherlife.com/uploads/20230518/700c59e4f82a0128df1e312100221fb6.png" alt="" /><img src="https://zhihuizhaoshang.wisherlife.com/uploads/20230518/700c59e4f82a0128df1e312100221fb6.png" alt="" /><img src="https://zhihuizhaoshang.wisherlife.com/uploads/20230518/700c59e4f82a0128df1e312100221fb6.png" alt="" /> ',
      loginImage: [
        require('../assets/right_img1.png'),
        require('../assets/right_img2.png'),
        require('../assets/right_img3.png'),
        require('../assets/right_img4.png'),
        require('../assets/right_img5.png'),
        require('../assets/right_img6.png'),
        require('../assets/right_img7.png'),
        require('../assets/right_img8.png'),
      ],
      markerList: [



      ],
      isDetailClickProcessing: false


    }
  },
  created () {


  },
  mounted () {
    this.getProjectList()
    this.getCarrierList()
    this.getToConfigure()
    this.getProjectData()
    // 获取当前时间戳（毫秒）
    const currentTimestamp = Date.now()

    // 创建 Date 对象并设置时间戳
    const date = new Date(currentTimestamp)

    // 获取年、月、日
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // 注意：月份从 0 开始，所以要加 1
    const day = date.getDate()

    // 拼接日期字符串
    const formattedDate = `${year}年${month}月${day}日`


    this.date = formattedDate
  },
  methods: {
    closeDialog () {
      this.show = false
      this.towndetail = {}
    },
    // async TownDetail () {
    //   let data = {
    //     id: this.mapId
    //   }
    //   const res = await TownDetail(data)
    //   console.log(res, 'towndetail')
    //   this.towndetail = res.data.data
    // },
    async detailClick (options) {
      // 如果事件正在处理中，则直接返回，避免重复触发
      if (this.isDetailClickProcessing) {
        return
      }

      // 将标志变量设置为 true，表示事件处理开始
      this.isDetailClickProcessing = true

      try {
        console.log('detailClick', options)
        this.show = true
        // this.TownDetail()
        let data = {
          id: this.mapId
        }
        const res = await TownDetail(data)
        console.log(res, 'res123')
        this.towndetail = res.data.data
        const coordinate = res.data.data.coordinate.split(',')
        const number = parseFloat(coordinate[0]) // 将字符串转换为浮点数
        const number1 = parseFloat(coordinate[1]) // 将字符串转换为浮点数
        const lat = number.toFixed(6)
        const lng = number1.toFixed(6)

        console.log(res.data.data.park_list, 'res.data.data.park_list')
        // 将 park_list.data 数据赋值给 markerList
        this.markerList = res.data.data.park_list.data.map(item => ({

          lat: parseFloat(item.coordinate.split(',')[0]),
          lng: parseFloat(item.coordinate.split(',')[1]),
          libraryName: item.title,
          image: item.image,
          address: item.address,
          arean: item.arean
          // 其他字段的重新设置
          // 新字段名: item.原字段名
        }))

        if (this.show) {
          TMap('KAHBZ-M4LWZ-XDZXT-TJORK-UYEL6-ZWFZP').then(qq => {
            var map = new qq.maps.Map(document.getElementById('container'), {
              center: new qq.maps.LatLng(lat, lng),
              zoom: 15
            })
            var infoWin = new qq.maps.InfoWindow({
              map: map
            })
            console.log(this.markerList, 'this.markerList')
            for (let i = 0; i < this.markerList.length; i++) {
              (function (markerData) {
                console.log(markerData, 'markerData')
                var marker = new qq.maps.Marker({
                  position: new qq.maps.LatLng(markerData.lat, markerData.lng),
                  map: map
                })

                marker.libraryName = markerData.libraryName
                marker.image = markerData.image
                marker.address = markerData.address
                marker.arean = markerData.arean

                qq.maps.event.addListener(marker, 'click', () => {
                  console.log(marker, 'marker')
                  infoWin.open()
                  infoWin.setContent(
                    `<div style="text-align:center; margin:10px; color:#000;">
    ${marker.libraryName}
  </div>
  <div style="color:#000; width:220px; word-break: break-word;">
    <div>面积：${marker.arean}</div>
    <div>地址：${marker.address}</div>
  </div>
  <img src="${marker.image}" alt="" style="width: 220px; height: 100px; margin-top:10px;">`
                  )


                  // 提示窗位置
                  infoWin.setPosition(new qq.maps.LatLng(markerData.lat, markerData.lng))
                })
              })(this.markerList[i])
            }
          })
        }

      } catch (error) {
        // 处理错误情况
      } finally {
        // 将标志变量设置回 false，表示事件处理完成
        this.isDetailClickProcessing = false
      }
    },
    goBackstage () {
      window.open('https://zhihuizhaoshang.wisherlife.com/aohvKVdEzm.php/dashboard?ref=addtabs')

    },
    handleMapClick (id) {
      this.mapId = id
      this.config.data = []
      this.show = false
      this.markerList = []
      this.getProjectList()
      this.getCarrierList()
      this.getProjectData()


    },
    async getProjectList () {
      let data = {
        page: 1,
        page_size: 30,
        town_id: this.mapId
      }
      const res = await getProjectList(data)
      this.projectList = res.data.data.data
    },
    async getCarrierList () {
      let data = {
        page: 1,
        page_size: 5,
        town_id: this.mapId
      }
      const res = await getCarrierList(data)
      this.total = res.data.data.total
      this.topList = res.data.data["tag_list "]
      for (const key in this.topList) {
        this.topList[key].area = Math.floor(this.topList[key].area)
      }
      console.log(this.topList[1].area.length, 'this.topList')
      const arr = []
      for (const key in this.topList) {
        arr.push(
          {
            name: this.topList[key].name,
            value: this.topList[key].area
          }
        )
      }
      this.config.data = arr

    },
    async getToConfigure () {
      const res = await getToConfigure()
      console.log(res.data.time, 'time')
      this.configList = res.data.data

    },
    async getProjectData () {
      let data = {
        town_id: this.mapId
      }
      const res = await getProjectData(data)
      this.projectData = res.data.data.echart
      this.projectTotal = res.data.data.total
    }
  },
  computed: {
    classOption () {
      return {

        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: this.projectList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)

      }
    }
  }



}
</script>

<style lang="scss" scoped>
.seamless-warp {
  margin-top: 20px;
  height: 430px;
  overflow: hidden;
}
.scale-wrap {
  color: #fff;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  #container {
    width: 850px;
    height: 714px;
    position: relative;
    z-index: 999;
    left: 530px;
    top: 280px;
  }
  ::v-deep .el-dialog {
    margin-top: 0px !important;
    top: 400px;
    left: -35px;
    width: 33% !important;
    border-radius: 30px !important;
    background: #201e66 !important;
    border: 3px solid #fff;
    // height: 600px; /* 设置盒子的高度 */

    .introduce {
      padding: 0px 15px !important;
      color: #fff !important;
      font-size: 30px !important;
      overflow-y: auto; /* 添加垂直滚动条 */

      height: 480px;
      img {
        width: 100% !important;
      }
    }
    /* 隐藏滚动条的轨道和滑块 */
    .introduce::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }
  ::v-deep .el-dialog__title {
    color: #fff !important;
    font-size: 28px !important;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff !important;
    font-size: 34px !important;
  }
  ::v-deep .el-dialog__header {
    padding: 25px;
    border-bottom: 2px solid #fff !important;
  }
  .bgc {
    width: 100%;
    height: 100%;
    background: url("../assets/background.jpg") center 0;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // left: 20px;
    .top_title {
      width: 1920px;
      height: 80px;
      background-image: url("../assets/title.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      line-height: 70px;
      text-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
      position: relative;
      .leftTime {
        position: absolute;
        left: 65px;
        top: 23px;
        font-size: 18px;
        font-weight: normal;
      }
      .goBackstage {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 18px;
        font-weight: normal;
        cursor: pointer;
        width: 200px;
        height: 44px;
      }
    }
    .top {
      position: absolute;
      left: 20px;
      top: 80px;
      z-index: 2;
      width: 1355px;
      height: 220px;
      background-image: url("../assets/top_bg.jpg");
      // background-color: #1b1764;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .title {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-weight: normal;
        margin-left: 20px;
        color: #fff;
        // // background-image: url("../assets/title_bg1.jpg");
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        font-weight: bold;
      }
      .content {
        display: flex;
        padding: 20px 20px 10px 20px;
        .contentItem {
          width: 270px;
          margin-right: 5px;
          height: 130px;
          // border: 1px solid #86fcfd;
          background-color: #1a216c;
          border-radius: 30px;
          background-image: url("../assets/top_img.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          .contentItemTitle {
            font-size: 16px;
            padding-left: 60px;
            height: 40px;
            line-height: 40px;
            padding-top: 13px;
          }
          .contentText {
            text-align: center;
            font-size: 40px;
            font-weight: bold;
            font-style: italic;
            text-shadow: 0 0 10px #3b79de, 0 0 20px #3b79de, 0 0 30px #3b79de,
              0 0 40px #3b79de; //设置发光效果
          }
          .contentItemBody {
            display: flex;
            // margin-top: 15px;
            font-size: 18px;
            .max {
              font-size: 12px !important;
            }
            .total {
              width: 40%;
              padding-left: 20px;
              text-align: center;
            }
            .theMeasureOfArea {
              width: 41%;
            }
          }
        }
        .contentItem:nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }
    .left {
      position: absolute;
      left: 20px;
      top: 310px;
      z-index: 2;
      width: 500px;
      height: 766px;
      // background-image: url("../assets/left_bg.jpg");
      background-color: #1e1661;
      box-sizing: border-box;

      .chart {
        width: 300px;
        height: 300px;
        margin-left: 120px;
        margin-top: -20px;
      }
      .msg {
        font-size: 18px;
        .msgLeft {
          width: 100%;
          padding-left: 48px;
          display: flex;
          flex-wrap: wrap;

          .msgItem {
            width: 50%;
          }
        }
      }
      .leftBottom {
        width: 100%;
        height: 340px;
        margin-top: -80px;
        .leftBottomItem {
          width: 98%;
          height: 33.33%;
          margin-left: 4px;
          display: flex;
          background-image: url("../assets/left_img.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;

          .img {
            width: 30%;
          }
          .message {
            margin-right: 35px;
            width: 70%;
            .messageTitle {
              width: 100%;
              height: 55px;
              line-height: 55px;
              // background-image: url("../assets/title-bg.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              font-size: 16px;
              margin-top: 12px;
              font-weight: bold;
              padding-left: 15px;
            }
            .messageNumber {
              margin-top: -5px;
              display: flex;
              font-size: 16px;
              text-align: center;
              .total {
                width: 33.33%;
              }
              .month {
                width: 33.33%;
              }
              .lastMonth {
                width: 33.33%;
              }
            }
          }
        }
        .leftBottomItem:nth-child(2) {
          background-image: url("../assets/left_img1.png");
        }
        .leftBottomItem:nth-child(3) {
          background-image: url("../assets/left_img2.png");
        }
      }
    }
    .right {
      position: absolute;
      right: 20px;
      top: 97px;
      z-index: 2;
      width: 510px;
      height: 977px;
      background-image: url("../assets/right_bg.jpg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      // background-color: #1b1764;
      .title {
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        font-weight: normal;
        color: #fff;
        font-weight: bold;
        margin-left: 25px;
        margin-top: -1px;
      }
      .projectTotal {
        padding-left: 20px;
        // padding-top: 10px;
        font-size: 16px;
      }
      .projectBox {
        width: 510px;
        display: flex;
        flex-wrap: wrap;
        // margin-top: 5px;
        .projectItem {
          width: 45%;
          margin-left: 20px;
          height: 90px;
          // border: 2px solid red;
          margin-top: 10px;
          font-size: 14px;
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .projectTitle {
            height: 40px;
            line-height: 45px;
            padding-left: 10px;
            // // background-image: url("../assets/title-bg.png");
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
          }
          .projectNumber {
            display: flex;
            margin-top: 5px;
            .projectNumberLeft {
              width: 60%;
              font-size: 30px;
              font-weight: bold;
              color: #f7e052;
              padding-left: 10px;
            }
            .projectNumberRight {
            }
          }
        }
      }

      .pendingProcessing {
        width: 100%;
        margin-top: 10px;

        .pendingProcessingItem {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          margin: 10px auto;
          border: 1px solid #4659bf;
          width: 90%;
          height: 40px;
          line-height: 40px;
          border-radius: 0px 0px 10px 0px;
          font-size: 16px;
          border-left: 5px solid #fff;

          .pendingProcessingText {
            padding-left: 30px;
          }
          .pendingProcessingButton {
            border: 1px solid #5fc2d1;
            width: 80px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            margin-top: 6px;
            margin-right: 20px;
          }
        }
      }

      .paging {
        display: flex;
        font-size: 16px;
        margin-left: 182px;
        margin-top: -10px;
        .prev,
        .next {
          width: 90px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border: 2px solid #60b1cd;
          margin-right: 15px;
          margin-top: 45px;
        }
      }
    }
    .center {
      position: absolute;
      right: 605px;
      top: 350px;
      z-index: 2;
      width: 780px;
      height: 720px;
      // background-image: url("../assets/panel.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .img {
        position: absolute;
        left: -10px;
        top: -37px;
      }
      .text {
        position: absolute;
        left: 45px;
        font-size: 18px;
        top: -30px;
      }
    }
  }
}
</style>
