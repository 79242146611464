import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'


axios.defaults.baseURL = process.env.VUE_APP_API_URL

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.use(dataV)
Vue.use(ElementUI);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
