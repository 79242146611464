<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {

}
</script>

<style scoped>
html {
  overflow: auto;
}
#app {
  -webkit-font-smoothing: "antialiased";
  -moz-osx-font-smoothing: "grayscale";
  /* text-align: center; */
  /* color: #51fdc1; */
  height: 100vh;
  width: 100%;
  background-color: #1d224f;
}
</style>
