import request from '@/utils/request'


// 项目列表
export function getProjectList(data) {
  return request({
    url: '/index/web/project_list',
    method: 'post',
    data
  })
}

// 载体信息
export function getCarrierList(data) {
  return request({
    url: '/index/web/carrier_info',
    method: 'post',
    data
  })
}

// 大屏配置
export function getToConfigure() {
  return request({
    url: '/index/web/pc_config',
    method: 'post'
  })
}


// 招商项目数据
export function getProjectData(data) {
  return request({
    url: '/index/web/get_echart_project_data',
    method: 'post',
    data
  })
}

// 街道信息
export function getTownList(data) {
  return request({
    url: '/api/town/get_page_list',
    method: 'post',
    data
  })
}
// 街道详情
export function TownDetail(data) {
  return request({
    url: '/index/web/town_detail',
    method: 'post',
    data
  })
}