import axios from 'axios'


// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
// 请求拦截器
service.interceptors.request.use(
  config => {
  return config
  },
  error => {
  return Promise.error(error)
  })
  
  // 响应拦截器
service.interceptors.response.use(response => {
  
  // 对响应数据做些什么
  return response;
}, error => {
  // 对响应错误做些什么
  return Promise.reject(error);
});



export default service
