<template>
  <div class="echarts">
    <div id="myChart" style="width: 800px; height: 700px"></div>
    <!-- <img
      v-if="showImage"
      :style="imageStyle"
      src="../../assets/arrow.png"
      alt=""
      @click="disableClick"
    /> -->
    <div class="close" @click="close()"></div>
  </div>
</template>

<script>
// 引入 ECharts
import * as echarts from "echarts"
// 引入福州市地图数据
import fuzhouMap from '@/assets/minhou.json'
import { getTownList } from '@/api/index'
export default {
  data () {
    return {
      showImage: false,
      townList: [],
      imageStyle: {
        position: 'absolute',
        zIndex: 999,
        top: '0px',
        left: '0px',
        width: '60px',
        height: '60px'
      },
      myChart: null
    }
  },
  mounted () {
    this.getTownList()
    this.initChart()


  },
  methods: {
    async getTownList () {
      let data = {
        page_size: 9999
      }
      const res = await getTownList(data)
      console.log(res, 'town')
      this.townList = res.data.data.data
    },
    disableClick (event) {
    },
    initChart () {
      this.myChart = echarts.init(document.getElementById('myChart'))

      // 注册地图
      echarts.registerMap('minhou', fuzhouMap)
      var tooltipParams
      var data = this.townList
      var option = {


        title: {
          left: 'center',
          top: 20,
          textStyle: {
            color: '#fff',
            fontSize: 24,
            fontWeight: 'bold'
          }
        },



        geo: {
          map: 'minhou',
          roam: false,
          zoom: 1.23,
          top: '10%', // 向下偏移 10%
          select: {

            itemStyle: {
              areaColor: "#a19452",
              borderColor: "#eff989",
              borderWidth: "3"
            }
          },
          label: {
            show: true,
            position: 'inside',

            textStyle: {
              color: '#fff'
            }
          },

          itemStyle: {

            areaColor: '#1a2565',
            borderColor: '#3f7df7',
            borderWidth: 2,
            // 阴影模糊半径大小
            shadowBlur: 10,
            // 阴影颜色
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            // 阴影水平偏移距离
            shadowOffsetX: -15,
            // 阴影垂直偏移距离
            shadowOffsetY: 10,



          },
          emphasis: {
            // focus: "self", //突出选中的区域 其它区域谈化效果
            label: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },


            itemStyle: {
              areaColor: '#a19452',
              borderWidth: 3,
              borderColor: '#ffff63',

            }
          },


        },
        series: [
          {
            type: "map",
            map: 'minhou',
            roam: true, //是否开启鼠标缩放和平移漫游
            geoIndex: 0, // 不可缺少，否则无tooltip 指示效果
            emphasis: {
              label: {
                show: true,
                color: 'red' // 修改点击高亮的标签颜色
              },
              itemStyle: {
                areaColor: 'red' // 修改点击高亮的区域颜色
              }
            },
            data: [
              { name: "廷坪乡", id: '1' },
              { name: '大湖乡', id: '2' },
              { name: '洋里乡', id: '3' },
              { name: '小箬乡', id: '4' },
              { name: '江洋农场', id: '5' },
              { name: '白沙镇', id: '6' },
              { name: '荆溪镇', id: '7' },
              { name: '甘蔗街道', id: '8' },
              { name: '鸿尾乡', id: '9' },
              { name: '竹岐乡', id: '10' },
              { name: '上街镇', id: '11' },
              { name: '南屿镇', id: '12' },
              { name: '南通镇', id: '13' },
              { name: '祥谦镇', id: '14' },
              { name: '尚干镇', id: '15' },
              { name: '青口镇', id: '16' }
            ],

          }
        ],

        tooltip: {
          trigger: "item",
          enterable: true,
          triggerOn: 'click',
          axisPointer: { // 坐标轴指示器配置项。
            type: 'shadow', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。 
            snap: true, // 坐标轴指示器是否自动吸附到点上
          },
          textStyle: { // 提示框浮层的文本样式。
            color: '#41feff',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
          },
          className: 'custom-tooltip-box',
          padding: 0, // 提示框浮层内边距，      
          formatter: function (params) {
            tooltipParams = params
            return (
              `
                  <div style='width:265px;height:130px;' class='custom-tooltip-style'>
                  <div id='deleteButton' class="deleteButton"></div>
                     <div style='font-size:20px;padding-left:20px;font-weight:bold;font-style: italic;padding-top:25px'>${params.name}</div>
                     <div id="myButton" class='detail' style='width:230px;height:40px;background-color:#2745b5; margin:25px auto;text-align:center;line-height:40px;font-size:20px;font-weight:bold;font-style: italic;'></div>
                </div>
                `
            )

          },
        },
      }

      this.myChart.setOption(option)
      this.myChart.on('click', (params) => {
        let data = params.data
        this.townList.map(v => {
          if (v.title == data.name) {
            data.id = v.id
          }
        })
        const clickId = data.id
        console.log(clickId, 'clickId')
        this.$emit('mapId', clickId)
        switch (params.data.id) {
          case '1':
            this.imageStyle.top = '80px'
            this.imageStyle.left = '325px'
            break
          case '2':
            this.imageStyle.top = '150px'
            this.imageStyle.left = '355px'
            break
          case '3':
            this.imageStyle.top = '165px'
            this.imageStyle.left = '260px'
            break
          case '4':
            this.imageStyle.top = '240px'
            this.imageStyle.left = '225px'
            break
          case '5':
            this.imageStyle.top = '240px',
              this.imageStyle.left = '370px'
            break
          case '6':
            this.imageStyle.top = '285px',
              this.imageStyle.left = '320px'
            break
          case '7':
            this.imageStyle.top = '300px',
              this.imageStyle.left = '390px'
            break
          case '8':
            this.imageStyle.top = '337px',
              this.imageStyle.left = '367px'
            break
          case '9':
            this.imageStyle.top = '370px',
              this.imageStyle.left = '245px'
            break
          case '10':
            this.imageStyle.top = '370px',
              this.imageStyle.left = '320px'

            break
          case '11':
            this.imageStyle.top = '412px',
              this.imageStyle.left = '386px'

            break
          case '12':
            this.imageStyle.top = '528px',
              this.imageStyle.left = '386px'

            break
          case '13':
            this.imageStyle.top = '528px',
              this.imageStyle.left = '442px'

            break
          case '14':
            this.imageStyle.top = '507px',
              this.imageStyle.left = '490px'
            break
          case '15':
            this.imageStyle.top = '535px',
              this.imageStyle.left = '502px'
            break
          case '16':
            this.imageStyle.top = '580px',
              this.imageStyle.left = '500px'
            break
          default:
            break
        }

        const button = document.getElementById('myButton')
        // 定义一个标志变量，表示事件是否正在处理中
        let isProcessing = false

        if (button) {
          button.addEventListener('click', async (params) => {
            // 如果事件正在处理中，则直接返回，避免重复点击
            if (isProcessing) {
              return
            }

            // 将标志变量设置为 true，表示事件处理开始
            isProcessing = true

            try {
              // 执行事件处理逻辑
              // console.log(tooltipParams)
              this.$emit('detailId', tooltipParams.data)

              // 等待一段时间，模拟事件处理耗时操作
              await new Promise(resolve => setTimeout(resolve, 1000))

              // 处理完成后将标志变量设置为 false
              isProcessing = false
            } catch (error) {
              // 处理错误情况，并确保将标志变量重置为 false
              isProcessing = false
              console.error(error)
            }
          })
        }

        const deleteButton = document.getElementById('deleteButton')
        if (deleteButton) {
          deleteButton.addEventListener('click', (params) => {
            this.showImage = false
            this.myChart.clear()
            this.myChart.setOption(option)
          })
        }


      })

    },
    resetChart () {
      // 清除当前的图表
      this.myChart.clear()
      var tooltipParams
      var data = this.townList
      var option = {


        title: {
          left: 'center',
          top: 20,
          textStyle: {
            color: '#fff',
            fontSize: 24,
            fontWeight: 'bold'
          }
        },



        geo: {
          map: 'minhou',
          roam: false,
          zoom: 1.23,
          top: '10%', // 向下偏移 10%
          select: {

            itemStyle: {
              areaColor: "#a19452",
              borderColor: "#eff989",
              borderWidth: "3"
            }
          },
          label: {
            show: true,
            position: 'inside',

            textStyle: {
              color: '#fff'
            }
          },

          itemStyle: {

            areaColor: '#1a2565',
            borderColor: '#3f7df7',
            borderWidth: 2,
            // 阴影模糊半径大小
            shadowBlur: 10,
            // 阴影颜色
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            // 阴影水平偏移距离
            shadowOffsetX: -15,
            // 阴影垂直偏移距离
            shadowOffsetY: 10,



          },
          emphasis: {
            // focus: "self", //突出选中的区域 其它区域谈化效果
            label: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },


            itemStyle: {
              areaColor: '#a19452',
              borderWidth: 3,
              borderColor: '#ffff63',

            }
          },


        },
        series: [
          {
            type: "map",
            map: 'minhou',
            roam: true, //是否开启鼠标缩放和平移漫游
            geoIndex: 0, // 不可缺少，否则无tooltip 指示效果
            emphasis: {
              label: {
                show: true,
                color: 'red' // 修改点击高亮的标签颜色
              },
              itemStyle: {
                areaColor: 'red' // 修改点击高亮的区域颜色
              }
            },
            data: [
              { name: "廷坪乡", id: '1' },
              { name: '大湖乡', id: '2' },
              { name: '洋里乡', id: '3' },
              { name: '小箬乡', id: '4' },
              { name: '江洋农场', id: '5' },
              { name: '白沙镇', id: '6' },
              { name: '荆溪镇', id: '7' },
              { name: '甘蔗街道', id: '8' },
              { name: '鸿尾乡', id: '9' },
              { name: '竹岐乡', id: '10' },
              { name: '上街镇', id: '11' },
              { name: '南屿镇', id: '12' },
              { name: '南通镇', id: '13' },
              { name: '祥谦镇', id: '14' },
              { name: '尚干镇', id: '15' },
              { name: '青口镇', id: '16' }
            ],

          }
        ],

        tooltip: {
          trigger: "item",
          enterable: true,
          triggerOn: 'click',
          axisPointer: { // 坐标轴指示器配置项。
            type: 'shadow', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。 
            snap: true, // 坐标轴指示器是否自动吸附到点上
          },
          textStyle: { // 提示框浮层的文本样式。
            color: '#41feff',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
          },
          className: 'custom-tooltip-box',
          padding: 0, // 提示框浮层内边距，      
          formatter: function (params) {
            tooltipParams = params
            return (
              `
                  <div style='width:265px;height:130px;' class='custom-tooltip-style'>
                  <div id='deleteButton' class="deleteButton"></div>
                     <div style='font-size:20px;padding-left:20px;font-weight:bold;font-style: italic;padding-top:25px'>${params.name}</div>
                     <div id="myButton" class='detail' style='width:230px;height:40px;background-color:#2745b5; margin:25px auto;text-align:center;line-height:40px;font-size:20px;font-weight:bold;font-style: italic;'></div>
                </div>
                `
            )

          },
        },
      }

      this.myChart.setOption(option)
      this.myChart.on('click', (params) => {
        let data = params.data
        this.townList.map(v => {
          if (v.title == data.name) {
            data.id = v.id
          }
        })
        const clickId = data.id
        console.log(clickId, 'clickId')
        this.$emit('mapId', clickId)
        switch (params.data.id) {
          case '1':
            this.imageStyle.top = '80px'
            this.imageStyle.left = '325px'
            break
          case '2':
            this.imageStyle.top = '150px'
            this.imageStyle.left = '355px'
            break
          case '3':
            this.imageStyle.top = '165px'
            this.imageStyle.left = '260px'
            break
          case '4':
            this.imageStyle.top = '240px'
            this.imageStyle.left = '225px'
            break
          case '5':
            this.imageStyle.top = '240px',
              this.imageStyle.left = '370px'
            break
          case '6':
            this.imageStyle.top = '285px',
              this.imageStyle.left = '320px'
            break
          case '7':
            this.imageStyle.top = '300px',
              this.imageStyle.left = '390px'
            break
          case '8':
            this.imageStyle.top = '337px',
              this.imageStyle.left = '367px'
            break
          case '9':
            this.imageStyle.top = '370px',
              this.imageStyle.left = '245px'
            break
          case '10':
            this.imageStyle.top = '370px',
              this.imageStyle.left = '320px'

            break
          case '11':
            this.imageStyle.top = '412px',
              this.imageStyle.left = '386px'

            break
          case '12':
            this.imageStyle.top = '528px',
              this.imageStyle.left = '386px'

            break
          case '13':
            this.imageStyle.top = '528px',
              this.imageStyle.left = '442px'

            break
          case '14':
            this.imageStyle.top = '507px',
              this.imageStyle.left = '490px'
            break
          case '15':
            this.imageStyle.top = '535px',
              this.imageStyle.left = '502px'
            break
          case '16':
            this.imageStyle.top = '580px',
              this.imageStyle.left = '500px'
            break
          default:
            break
        }
        const button = document.getElementById('myButton')
        if (button) {
          button.addEventListener('click', (params) => {
            console.log(tooltipParams)
            // alert('点击了按钮！')
            this.$emit('detailId', tooltipParams.data)
          })

        }
        const deleteButton = document.getElementById('deleteButton')
        if (deleteButton) {
          deleteButton.addEventListener('click', (params) => {
            this.showImage = false
            this.myChart.clear()
            this.myChart.setOption(option)
          })
        }
      })

    },
    close () {
      this.resetChart()
      // 其他的关闭逻辑
      this.showImage = false
      this.$emit('mapId', '')
    },
  }
};
</script> 

<style scoped lang='scss'>
// 给父盒子清除默认已有样式
:deep(.custom-tooltip-box) {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  // 给子盒子自定义样式
  .custom-tooltip-style {
    width: 92px;
    height: 171px;
    background-image: url("@/assets/detail_box.png");
    background-size: cover;
    color: #fff;
    position: relative;
    .detail {
      background-image: url("@/assets/detail.png");
    }
    .deleteButton {
      width: 16px;
      height: 16px;
      background-image: url("@/assets/delete.png");
      position: absolute;
      right: 8px;
      top: 14px;
    }
  }
}
.close {
  position: absolute;
  top: -36px;
  left: -10px;
  width: 125px;
  height: 35px;
  cursor: pointer;
  // background-color: skyblue;
}
</style>
